import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import MarkdownRenderers from '../../utils/LegalMarkdownRenderers';

const DataProtectionInformation: FC = () => {
  const { t } = useTranslation('legal-data-protection');
  const dpi = t('default');

  return (
    <div className="mt-2 pb-8">
      <ReactMarkdown remarkPlugins={[gfm]} components={MarkdownRenderers}>
        {dpi}
      </ReactMarkdown>
    </div>
  );
};

export default DataProtectionInformation;
